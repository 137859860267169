import styled from '@emotion/styled'

export const Title = styled.h1`
  margin: 30px 0;
  text-align: center;
`

export const Subtitle = Title.withComponent('h2')

export const Description = styled.article`
  margin: 0 45px 30px;
  ${(props) => (props.centered ? 'text-align: center;' : '')}
`
