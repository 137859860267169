import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../layouts/default'
import SEO from '../components/seo'
import MemberSlider from '../components/memberSlider'
import { Title, Description } from '../components/common/text'

const memberMapper = ({ frontmatter, html }) => ({
  name: frontmatter.title,
  subtitle: frontmatter.subtitle,
  role: frontmatter.role,
  image: frontmatter.image,
  descriptionMd: html,
})

function AboutUsPage({ data }) {
  return (
    <Layout>
      <SEO title="Nuestro Equipo de Trabajo" />
      <Title>Nuestro Equipo de Trabajo</Title>
      <div className="container">
        <MemberSlider
          members={data.members.nodes.map(memberMapper)}
          slidesShown={[1, 3]}
        />
        <Description dangerouslySetInnerHTML={{ __html: data.aboutUs.html }} />
      </div>
    </Layout>
  )
}

// TODO: Make this more detailed
AboutUsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query AboutUsDataQuery {
    members: allMarkdownRemark(
      filter: { frontmatter: { categories: { in: "team" } } }
      sort: { fields: frontmatter___order }
    ) {
      nodes {
        frontmatter {
          title
          subtitle
          role
          image {
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 400, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
              }
            }
          }
        }
        html
      }
    }

    aboutUs: markdownRemark(
      frontmatter: { title: { eq: "Nuestro Equipo de Trabajo" } }
    ) {
      html
    }
  }
`

export default AboutUsPage
