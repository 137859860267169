/* global window */
import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import Modal from 'react-modal'
import Img from 'gatsby-image'
import { Global } from '@emotion/react'

import ImageText from '../imageText'
import {
  Container,
  PrevArrow,
  NextArrow,
  MemberSlide,
  MemberImage,
  getModalTransitionsStyle,
  modalStyle,
  ModalHeader,
  ModalCloseButton,
} from './style'

import { isMobileViewport } from '../../utils'

const MODAL_TRANSITION_TIMEOUT = 300

Modal.setAppElement('#___gatsby')

function getSlidesShown(slideNumbers) {
  return isMobileViewport() ? slideNumbers[0] : slideNumbers[1]
}

const generateMember = (member, handleMemberPress) => {
  const { name, role, image } = member

  return (
    <React.Fragment key={name}>
      <MemberSlide
        image={image.childImageSharp.fluid}
        onClick={() => handleMemberPress(member)}
      >
        <MemberImage alt={name} fluid={image.childImageSharp.fluid} />
        <h3>{name}</h3>
        <p>{role}</p>
      </MemberSlide>
    </React.Fragment>
  )
}

function MemberSlider({ members, slidesShown }) {
  const [shownSlides, setShownSlides] = useState(1)
  const [selectedMember, setSelectedMember] = useState(null)
  const [isSlide, setSlide] = useState(false)

  useEffect(() => {
    setShownSlides(getSlidesShown(slidesShown))

    function handleWindowChange() {
      setShownSlides(getSlidesShown(slidesShown))
    }

    window.addEventListener('resize', handleWindowChange)

    return function cleanup() {
      window.removeEventListener('resize', handleWindowChange)
    }
  }, [])

  const handleMemberPress = useCallback(
    (member) => {
      if (isSlide) setSlide(false)
      else setSelectedMember(member)
    },
    [isSlide],
  )

  const handleModalClose = useCallback(() => setSelectedMember(null))

  const {
    name: memberName,
    subtitle: memberSubtitle,
    role: memberRole,
    image: memberImage,
    descriptionMd: memberDescription,
  } = selectedMember || {}

  return (
    <Container>
      <Slider
        speed={500}
        slidesToShow={shownSlides}
        slidesToScroll={1}
        swipeToSlide
        prevArrow={<PrevArrow />}
        nextArrow={<NextArrow />}
        beforeChange={() => setSlide(true)}
        afterChange={() => setSlide(false)}
      >
        {members.map((member) => generateMember(member, handleMemberPress))}
      </Slider>
      <Global styles={getModalTransitionsStyle(MODAL_TRANSITION_TIMEOUT)} />
      <Modal
        isOpen={Boolean(selectedMember)}
        onRequestClose={handleModalClose}
        closeTimeoutMS={MODAL_TRANSITION_TIMEOUT}
        contentLabel={memberName}
        style={modalStyle}
      >
        <ModalHeader>
          <section>
            <h2>{memberName}</h2>
            <h3>{memberRole}</h3>
            <p>{memberSubtitle}</p>
          </section>
          <ModalCloseButton onClick={handleModalClose}>X</ModalCloseButton>
        </ModalHeader>
        <ImageText>
          <Img
            alt={memberName}
            fluid={memberImage && memberImage.childImageSharp.fluid}
          />
          <article dangerouslySetInnerHTML={{ __html: memberDescription }} />
        </ImageText>
      </Modal>
    </Container>
  )
}

MemberSlider.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      image: PropTypes.object,
      descriptionMd: PropTypes.string,
    }),
  ).isRequired,
  slidesShown: PropTypes.arrayOf(PropTypes.number).isRequired,
}

export default MemberSlider
