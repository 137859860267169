import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Img from 'gatsby-image'
import colors from '../../styles/colors'

const MAX_PROFILE_WIDTH = 300

export const Container = styled.section`
  padding: 0 35px;
`

export const PrevArrow = styled.div`
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;

  border-right: 15px solid ${colors.primary};

  :before {
    display: none;
  }
`

export const NextArrow = styled.div`
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;

  border-left: 15px solid ${colors.primary};

  :before {
    display: none;
  }
`

export const MemberSlide = styled.div`
  margin: 30px auto;
  text-align: center;
  max-width: ${({ image }) => (image.presentationWidth
    ? `${Math.min(image.presentationWidth, MAX_PROFILE_WIDTH)}px`
    : 'auto')};

  h1,
  h2,
  h3 {
    margin: 15px 0 0;
    text-align: center;
    font-weight: normal;
    font-style: italic;
  }
`

// Fallback to style object to pass as Img prop
export const MemberImage = styled(Img)`
  border-radius: 50%;
`

export const getModalTransitionsStyle = (transitionTimeout) => css`
  .ReactModal__Overlay {
    opacity: 0;
    transform: scale(0);
    transition: all ${transitionTimeout}ms ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
    transform: scale(1);
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
    transform: scale(0);
  }
`

export const modalStyle = {
  overlay: {
    zIndex: 5,
  },
}

export const ModalHeader = styled.header`
  display: flex;
  justify-content: space-between;

  h3 {
    font-weight: normal;
  }

  p {
    font-style: italic;
  }
`

export const ModalCloseButton = styled.button`
  border-radius: 50%;
  box-shadow: none;
  border: none;
  background-color: ${colors.primary};
  color: ${colors.inverted.primary};
  height: 30px;
  width: 30px;
  font-weight: bold;
  flex-shrink: 0;
`
